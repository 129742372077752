// 引入axios配置
import request from './index'
import qs from 'qs'
let currentcode = 'apis'

// 封装请求
export function fetchPv(pv) {
  return request({
    url: '/vue-element-admin/article/pv',
    method: 'get',
    params: { pv }
  })
}

export function createArticle(data) {
  return request({
    url: '/vue-element-admin/article/create',
    method: 'post',
    data
  })
}

// 请求地图资源
export function mapjson (data) {
  return request({
    url: `/map/${data}_full.json`,
    method: 'get',
  })
}

// 注册接口
export function registered (data) {
  return request({
    url: currentcode+'/accounts/sign_up/',
    method: 'post',
    data: data
  })
}
// 验证账号邮箱是否重名接口  user_email/user_username/user_forgot_email
export function Check_weight (data) {
  return request({
    url: currentcode+'/accounts/check/',
    method: 'post',
    data: data
  })
}
// 获取邮箱验证码  参数 user_signup为注册时的字段   user_forgot_pwd为忘记密码时的字段
export function Verification_code (data) {
  return request({
    url: currentcode+'/accounts/send_captcha/',
    method: 'post',
    data: data 
  })
}
// 修改密码接口
export function forget_pow (data) {
  return request({
    url: currentcode+'/accounts/forgot_pwd/',
    method: 'post',
    data: data 
  })
}
// 登录接口
export function login (data) {
  return request({
    url: currentcode+'/accounts/login/',
    method: 'post',
    data: data 
  })
}
// 退出登录接口
export function logout (data) {
  return request({
    url: currentcode+'/accounts/logout/',
    method: 'post',
    data: data 
  })
}
