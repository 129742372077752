<template>
  <div class="footer-box"></div>
</template>
<script>
export default {
  
}
</script>
<style scoped>
  .footer-box {
    height: 200px;
    width: 100%;
    background: #312F38;
  }
</style>