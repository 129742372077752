import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



// 引入公共样式
import './static/css/component.css'
Vue.config.productionTip = false

// 引入icon图标
import './static/css/iconfont.css'

// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入echarts注册实例
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入qs注册实例
import qs from 'qs'
Vue.prototype.$qs = qs


router.beforeEach((to, from, next) => {
  console.log(store.state.username)
  if (store.state.username !== '') {
    if (to.name == 'Loginpage') {
      next({ path: from.path })
    } else {
      next()
    }
  }
  // if (to.name == 'Loginpage' && store.state.username !== '账号未登录') {
  //   next()
  // } 
  else {
    next()
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
