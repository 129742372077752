<template>
  <div class="loginpage_box">
    <div class="header_box">
      <img src="../static/img/logo.png" alt="">
    </div>
    <div class="user_box">
      <div class="user_form">
        <div class="user_form_img">
          <img src="../static/img/form-logo1.png" />
          <img src="../static/img/form-logo2.png" />
        </div>
        <transition  mode="out-in" name="slide-left">
          <div class="login_box" v-if="currentcode == 'login'" key="login">
            <el-form ref="loginform" :model="loginform" :rules="loginrules" label-position="top" :hide-required-asterisk="true">
              <el-form-item label="账号" prop="username">
                <el-input placeholder="请输入账号邮箱" v-model="loginform.username"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input placeholder="请输入密码" show-password v-model="loginform.password"></el-input>
              </el-form-item>
              <el-form-item class="sub_button">
                <el-button type="primary" @click="submitForm('loginform')">登录</el-button>
              </el-form-item>
            </el-form>
            <div class="tool_pox">
              <span @click="goto('forget')">忘记密码</span>
              <span @click="goto('registered')">没有账号？前往注册</span>
            </div>
          </div>
          <div class="forget_box" v-else-if="currentcode == 'forget'" key="forget">
            <el-form ref="forgetform" :model="forgetform" :rules="forgetrules" label-position="top" :hide-required-asterisk="true">
              <el-form-item label="邮箱" prop="email" :class="!show_forget_yzcode? 'disabled_button':''">
                <el-input placeholder="请输入邮箱以获取验证码" v-model="forgetform.email">
                  <el-button slot="append" @click="get_yz_code('forget')" :disabled="!show_forget_yzcode">
                    获取验证码
                    <span v-show="!show_forget_yzcode" class="count">({{forget_yzcode_count}}s)</span>
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="yz_code">
                <el-input placeholder="请输入验证码" v-model="forgetform.yz_code"></el-input>
              </el-form-item>
              <el-form-item label="请输入密码" prop="password">
                <el-input placeholder="请输入密码" show-password v-model="forgetform.password"></el-input>
              </el-form-item>
              <el-form-item label="再次确认密码" prop="again_password">
                <el-input placeholder="请输入密码" show-password v-model="forgetform.again_password"></el-input>
              </el-form-item>
              <el-form-item class="sub_button">
                <el-button type="primary" @click="submitForm('forgetform')">确定</el-button>
              </el-form-item>
            </el-form>
            <div class="tool_pox">
              <span></span>
              <span @click="goto('login')">已有账号，前去登录</span>
            </div>
          </div>
          <div class="registered_box" v-else  key="registered">
            <el-form ref="registeredform" :model="registeredform" :rules="registeredrules" label-position="top" :hide-required-asterisk="true">
              <el-form-item label="账号" prop="username">
                <el-input placeholder="请输入账号" v-model="registeredform.username"></el-input>
              </el-form-item>
              <el-form-item label="邮箱账号" prop="email" :class="!show_registered_yzcode? 'disabled_button':''">
                <el-input placeholder="请输入邮箱以获取验证码" v-model="registeredform.email">
                  <el-button slot="append" @click="get_yz_code('registered')" :disabled="!show_registered_yzcode">
                    获取验证码
                    <span v-show="!show_registered_yzcode" class="count">({{registered_yzcode_count}}s)</span>
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="yz_code">
                <el-input placeholder="请输入验证码" v-model="registeredform.yz_code"></el-input>
              </el-form-item>
              <el-form-item label="请输入密码" prop="password">
                <el-input placeholder="请输入密码" show-password v-model="registeredform.password"></el-input>
              </el-form-item>
              <el-form-item label="再次确认密码" prop="again_password">
                <el-input placeholder="请输入密码" show-password v-model="registeredform.again_password"></el-input>
              </el-form-item>
              <el-form-item class="sub_button">
                <el-button type="primary" @click="submitForm('registeredform')">确定</el-button>
              </el-form-item>
            </el-form>
            <div class="tool_pox">
              <span></span>
              <span @click="goto('login')">已有账号，前去登录</span>
            </div>
          </div>
        </transition>    
      </div>
      <div class="bg_box">

      </div>
    </div>
  </div>
</template>
<script>
import {registered, Check_weight, Verification_code, forget_pow, login} from '../axios/request'
export default {
  data () {
    return {
      currentcode: 'login',
      // 登录
      loginform: {
        username: "",
        password: ""
      },
      loginrules: {
        username: [
          { required: true, message: '请输入账号或者邮箱', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ]
      },
      // 修改密码
      forgetform: {
        email: "",
        yz_code: "",
        password: "",
        again_password: ""
      },
      forgetrules: {
        email: [ { validator: this.forget_email, trigger: 'blur' }
          // { required: true, message: '请输入邮箱', trigger: 'blur' },
          // { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        yz_code: [{ validator: this.forget_validate_yz_code, trigger: 'blur' }],
        password: [
          { validator: this.forget_validatePass, trigger: 'blur' }
        ],
        again_password: [
          { validator: this.forget_validatePass2, trigger: 'blur' }
        ]
      },
      // 注册账号
      registeredform: {
        username: "",
        email: "",
        yz_code: "",
        password: "",
        again_password: ""
      },
      registeredrules: {
        username: [
          { validator: this.username_yz, trigger: 'blur' }
        ],
        email: [
          { validator: this.email_yz, trigger: 'blur' },
        ],
        yz_code: [{ validator: this.registered_validate_yz_code, trigger: 'blur' }],
        password: [{ validator: this.validatePass, trigger: 'blur' }],
        again_password: [{ validator: this.validatePass2, trigger: 'blur' }]
      },

      // 忘记密码
      //倒计时
      forget_yzcode_count: 60,
      // 计时器
      forget_yzcode_timer: null,
      // 开关
      show_forget_yzcode: true,
      
      // 注册
      //倒计时
      registered_yzcode_count: 60,
      // 计时器
      registered_yzcode_timer: null,
      // 开关
      show_registered_yzcode: true,
      
      // 验证码
      forget_current_yz_code: "",
      registered_current_yz_code: "",
    }
  },
  methods: {
    goto (code) {
      this.currentcode = code
    },
    // 提交信息
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        // 规则验证通过
        if (valid) {
          // 请求接口提交数据
          if (formName == 'forgetform') {
            this.forgetapi()
          } else if (formName == 'registeredform') {
            this.registeredapi()
          } else {
           this.loginapi()
            // 路由跳转

          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 请求注册接口
    registeredapi () {
      let obj = {
        username: this.registeredform.username,
        password: this.registeredform.password,
        email: this.registeredform.email,
        captcha: this.registeredform.yz_code,
      }
      registered(obj).then(res => {
        if (res.data.code == 0) {
          this.open_to_login('注册完成！前去登陆')
        } else {
          this.message_open('error', '注册失败')
        }
      }).catch(err => {
        this.message_open('error', '注册失败')
      })
      
    },

    // 请求修改密码接口
    forgetapi () {
      let obj = {
        password: this.forgetform.password,
        email: this.forgetform.email,
        captcha: this.forgetform.yz_code
      }
      forget_pow(obj).then( res=> {
        if (res.data.code == 0) {
          this.open_to_login('密码修改完成！前去登陆。')
        } else if (res.data.code == 202) {
          if (res.data.data.captcha) {
            this.$refs.forgetform.fields[1].validateMessage ="验证码输入错误"
            this.$refs.forgetform.fields[1].validateState = 'error'
          }
        }
      }).catch(err => {
        this.message_open('error', '密码修改失败！')
      })
    },

    // 登录接口
    loginapi () {
      let obj = {
        password: this.loginform.password,
        username: this.loginform.username,
      }
      login(obj).then(res => {
        if (res.data.code == 0) {
          this.message_open('success', '登陆成功！')
          this.$store.commit('setusername', res.data.data.username)
          this.$router.push('/')
        } else {
          this.message_open('error', '账号或密码不正确！')
        }
         
        
      }).catch(err => {
        this.message_open('error', '账号或密码不正确！')
      })
    },

    // 获取验证码
    get_yz_code (codename) {
      let flat = false
      let obj = {}
      if (codename == 'registered') {
        this.$refs.registeredform.validateField("email", (res) => {
          if (!res) {
            obj.email = this.registeredform.email
            obj.do = "user_signup"
            flat = true
            yz_code(obj, codename)
          } else {
            flat = false
          }
        })
      } else {
        this.$refs.forgetform.validateField("email", (res) => {
          if (!res) {
            obj.email = this.forgetform.email
            obj.do = "user_forgot_pwd"
            flat = true
            yz_code(obj, codename)
          } else {
            flat = false
          }
        })
        
      }

      let yz_code = (obj, codename) => {
        Verification_code(obj).then(res => {
          if (res.data.code == 0) {
            this.message_open('success', '验证码发送成功！')
            if (codename == 'registered') {
              if (!this.registered_yzcode_timer) {
                this.show_registered_yzcode = false
                this.registered_yzcode_count = 60
                // 倒计时开始
                this.registered_yzcode_timer = setInterval(() => {
                  if (this.registered_yzcode_count > 0 && this.registered_yzcode_count <= 60) {
                    this.registered_yzcode_count--;
                  } else {
                    this.show_registered_yzcode = true;
                    clearInterval(this.registered_yzcode_timer); // 清除定时器
                    this.registered_yzcode_timer = null;
                  }
                }, 1000)
              }
            } else {
              if (!this.forget_yzcode_timer) {
                this.show_forget_yzcode = false
                this.forget_yzcode_count = 60
                this.forget_yzcode_timer = setInterval(() => {
                  if (this.forget_yzcode_count > 0 && this.forget_yzcode_count <= 60) {
                    this.forget_yzcode_count--;
                  } else {
                    this.show_forget_yzcode = true;
                    clearInterval(this.forget_yzcode_timer); // 清除定时器
                    this.forget_yzcode_timer = null;
                  }
                }, 1000)
              }
            }
          } else {
            this.message_open('error', '验证码发送失败！请检查邮箱是否正确')
          }
        }).catch(err => {
          console.log(err)
          this.message_open('error', '验证码发送失败！请检查邮箱是否正确')
        })
      }
      

      

      
      
    },


    // 忘记密码
    // 邮箱验证
    forget_email (rule, value, callback) {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('请输入邮箱'))
      } else {
        if (mailReg.test(value)) {
          let obj = {
            do: "user_forgot_email",
            val: value
          }
          Check_weight(obj).then(res => {
            if (res.data.code != 0) {
              callback(new Error('邮箱未注册'))
            } else {
              callback()
            }
          }).catch(err => {
            callback(new Error('验证失败'));
          })
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }
    },
    // 输入密码验证
    forget_validatePass (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length<6 || value.length>20) {
        callback(new Error('长度在 6 到 20 个字符'));
      }
      else {
        if (this.forgetrules.again_password !== '') {
          this.$refs.forgetform.validateField('again_password');
        }
        callback();
      }
    },
    // 再次输入密码验证
    forget_validatePass2 (rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value.length<6 || value.length>20) {
        callback(new Error('长度在 6 到 20 个字符'));
      }
      else if (value !== this.forgetform.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    // 判断验证码
    forget_validate_yz_code (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } 
      // else if (value !== this.forget_current_yz_code) {
      //   callback(new Error('验证码输入不正确!'));
      // } 
      else {
        callback();
      }
    },


    // 弹出修改密码成功确认弹框或注册成功确认弹框  确认按钮跳转到登录
    open_to_login (text) {


      this.$alert(text, '提示！', {
        confirmButtonText: '确定',
        callback: action => {
          this.currentcode = "login"
        }
      });
    },
    // 消息提示 提示修改成功/登录成功/注册成功/获取验证码成功
    message_open (type, message) {
      this.$message({
        message: message,
        type: type
      });
    },


    // 注册验证
    // 账号验证
    username_yz (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入账号'));
      } else if (value.length<6 || value.length>10) {
        callback(new Error('长度在 6 到 10 个字符'));
      }
      else {
        let obj = {
          do: "user_username",
          val: value
        }
        Check_weight(obj).then(res => {
          if (res.data.code == 202) {
            callback(new Error('账号已注册'));
          } else {
            callback();
          }
        }).catch(err => {
          callback(new Error('验证失败'));
        })
      } 
    },
    // 邮箱验证
    email_yz (rule, value, callback) {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (value === '') {
        callback(new Error('请输入邮箱'));
      } else {
        if (mailReg.test(value)) {
          let obj = {
            do: "user_email",
            val: value
          }
          Check_weight(obj).then(res => {
            if (res.data.code != 0) {
              callback(new Error('邮箱已注册'));
            } else {
              callback();
            }
          }).catch(err => {
            callback(new Error('验证失败'));
          })
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
        
      } 
    },
    // 判断验证码
    registered_validate_yz_code (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } 
      // else if (value !== this.registered_current_yz_code) {
      //   callback(new Error('验证码输入不正确!'));
      // } 
      else {
        callback();
      }
    },
    // 输入密码验证
    validatePass (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.registeredform.again_password !== '') {
          this.$refs.registeredform.validateField('registered_password');
        }
        callback();
      }
    },
    // 再次输入密码验证
    validatePass2 (rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registeredform.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    
    

  }
}
</script>
<style scoped>
  .loginpage_box {
    height: 100%;
    width: 100%;
    min-width: 1920px !important;
    overflow: auto;
    background: #F4F8FF;
  }
  .header_box {
    background: #001529;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_box > img {
    display: block;
    width: 167px;
    height: 35px;
  }
  .user_box {
    height: calc(100% - 128px);
    min-height: 739px;
    width: 70%;
    margin: 40px auto;
    background: #ffffff;
    border-radius: 13px;
    display: flex;
  }
  
  .user_form {
    width: 40%;
    padding: 75px 0;
    height: calc(100% - 150px);
  }
  .user_form > div {
    width: 65%;
    margin: 0 auto;
  }
  .user_form > .user_form_img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user_form > .user_form_img > img:first-child {
    display: block;
    height: 54px;
    width: 54px;
    padding-right: 6px;
    border-right: 1px solid rgba(3, 3, 3, 0.2);
  }
  .user_form > .user_form_img > img:last-child {
    margin-left: 24px;
    display: block;
    height: 35px;
    width: 116px;
  }
  .user_form  .login_box {
    margin-top: 80px;
  }
  .user_form  .forget_box,  .user_form  .registered_box {
    margin-top: 50px;
  }
  .tool_pox {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .tool_pox > span {
    cursor: pointer;
  }
  .tool_pox > span:hover {
    cursor: pointer;
    color: #167FFA;
  }
  /deep/.el-form-item__label {
    line-height: 20px;
  }
  /deep/.sub_button {
    margin-top: 30px;
  }
  /deep/.sub_button > div {
    display: flex;
    justify-content: center;
  }
  /deep/.sub_button > div > button {
    padding: 12px calc(50% - 15px);
  }


  /deep/.el-input-group__append {
    background-color: #1890FF;
    color: #FFF;
    border: 0;
  }
  /deep/.disabled_button .el-input-group__append {
    background-color: #ffffff;
  }
  /deep/.el-input-group__append > button {
    background-color: #1890FF;
    color: #FFF;
    border: 0;
    padding: 13px 20px;
  }
  /deep/.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
    background-color: #a0cfff;
    color: #FFF;
    border: #a0cfff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  /deep/.open_to_login_text {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  .bg_box {
    width: 60%;
    height: 100%;
    background: url('../static/img/loginbg.png') no-repeat 100%/100% 100%;
  }

  
 

  .flip-list-move {
    transition: transform .3s;
  }
  .slide-left-enter {
    opacity: 0;
		transform: translate3d(-20px,0,0)
	}
	
	.slide-left-enter-to {
		opacity:1;
		transform: translate3d(0,0,0)
	}
	
	.slide-left-leave-to{
		opacity:0;
		transform: translate3d(20px,0,0)
	}
	.slide-left-enter-active, .slide-left-leave-active {
		transition: all .3s linear
  }

</style>