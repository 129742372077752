<template>
  <div class="content-box">
    <div>
      <div class="top-box">
        <div class="img-fufei-box">
          <el-image
            style="width: 100%;"
            :src="itemdata.imgsrc"
            fit="cover">
          </el-image>
          <div class="fufei-box" :style="{background: itemdata.entid == '11'? '#FF6600':'#CC3333'}">
            <span>{{itemdata.entid == '11'? '免费':'付费'}}</span>
          </div>
        </div>
        
        
        <div class="text-box">
          <p>{{itemdata.textp}}</p>
          <ul>
            <li>{{itemdata.theme}}</li>
            <li>{{itemdata.time}}</li>
            <li>{{itemdata.text}}</li>
          </ul>
          <span>申请数据</span>
        </div>
      </div>

      <div class="bottom-box">
        <ul>
          <li :class="currentindex=='first'?'active-li':''" @click="cutbox('first')">数据概览</li>
          <li :class="currentindex=='second'?'active-li':''" @click="cutbox('second')">数据字典</li>
          <li :class="currentindex=='thired'?'active-li':''" @click="cutbox('thired')">数据样例</li>
        </ul>
        
        <transition name="slide-left" mode="out-in">
          <div v-if="currentindex == 'first'" class="first-box" key="first">
            <div>
              <font>数据库介绍</font>
              <div>

              </div>
              <p class="empty-text">暂无数据</p>
            </div>
            <div>
              <font>指标介绍</font>
              <div>
                
              </div>
              <p class="empty-text">暂无数据</p>
            </div>
            <div>
              <font>备注消息</font>
              <div>
                
              </div>
              <p class="empty-text">暂无数据</p>
            </div>
          </div>
          <div v-else-if="currentindex == 'second'" class="second-box" key="second">
            <font>数据字典列表</font>
            <el-table
              :data="tableData1"
              height="450"
              style="width: 100%">
              <el-table-column
                type="index"
                align="center"
                width="80"
                label="序号">
              </el-table-column>
              <el-table-column
                v-for="item in tableth1"
                :key="item.prop"
                align="center"
                :label="item.label"
                :prop="item.prop">
              </el-table-column>
            </el-table>
          </div>
          <div v-else-if="currentindex == 'thired'" class="thired-box" key="thired">
            <font>数据样例展示</font>
            <el-table
              :data="tableData2"
              height="450"
              style="width: 100%">
              <el-table-column
                v-for="(item, index) in tableth2"
                :fixed="index <= 1? 'left':false"
                :key="item.prop"
                :prop="item.prop"
                align="center"
                :label="item.label">
              </el-table-column>

            </el-table>
          </div>
        </transition>
        
        
      </div>
    </div>
    
    
  </div>
</template>
<script>
export default {
  data () {
    return {
      currentindex: "first",
      code: "",
      loading: false,

      itemdata: {
        entid: "11",
        textp: "那些更棒的点子来自于个体依然用过去习惯的方式思考创意时——坐在办公桌前时，在咖啡店等咖啡时，洗澡时。这些由人们独自想出的点子更胜一筹。当讨论会的狂热劲头散去，头脑风暴产生的点子其实并没有那么特别",
        imgsrc: require('../static/img/datalist1.png'),
        theme: "主题1",
        time: "2020-10-29",
        text: "内容"
      },
      tableth1: [
        {
          label: "字段",
          prop: "ziduan"
        },
        {
          label: "含义",
          prop: "hanyi"
        },
        {
          label: "字典",
          prop: "zidian"
        },


      ],
      tableData1: [],
      tableth2: [
        {
          label: "字段1",
          prop: "ziduan1"
        },
        {
          label: "字段2",
          prop: "ziduan2"
        },
        {
          label: "字段3",
          prop: "ziduan3"
        },
        {
          label: "字段4",
          prop: "ziduan4"
        },
      ],
      tableData2: []

    }
  },
  created () {
    this.itemdata = JSON.parse(window.sessionStorage.getItem("itemdata"))
    // 地址栏获取code
    // this.code = this.$route.query.code
    
    this.detailaxios()
    
    
  },
  methods: {
    // 请求数据详情
    detailaxios () {
        // 
    },
    cutbox (code) {
      this.currentindex = code
    }
  }
}
</script>
<style scoped>
  .content-box {
    background: #FAFDFF;
  }
  .content-box > div {
    width: 65%;
    margin:  auto;

  }
  .top-box {
    display: flex;
    align-items: center;
    margin: 10px 0 15px 0;
    padding: 8px 7px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
    background: #FFFFFF;
  }
  
  
  .img-fufei-box {
    position: relative;
    width: 205px;
    overflow: hidden;
  }
  .fufei-box {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    -moz-transform-origin: 100% 100%;
    -webkit-transform-origin:100% 100%;
    -o-transform-origin:100% 100%;
    transform:  rotate(45deg) translateY(-40%);
  }
  .fufei-box > span {
    position: absolute;
    bottom: 5%;
    font-size: 12px;
    color: #FFF;
    text-align: center;
  }
  .text-box {
    flex: 1;
    height: 100%;
    position: relative;
    margin-left: 30px;
  }
  .text-box p {
    margin-right: 86px;
    text-align: left;
    text-indent: 2em;
    font-size: 14px;
    color: rgba(37, 37, 37, 0.65);
    line-height: 20px;
    padding: 20px 20px 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .text-box ul {
    list-style: none;
    display: flex;
  }
  .text-box ul > li {
    padding: 5px 12px;
    margin-top: 8px;
    margin-right: 15px;
    font-size: 14px;
  }
  .text-box ul > li:nth-child(1) {
    color: #67c23a;
    background: #f0f9eb;
  }
  .text-box ul > li:nth-child(2) {
    color: #4091ff;
    background:#d9ecff;
  }
  .text-box ul > li:nth-child(3) {
    color: #F56C6C;
    background: #fde2e2;
  }
  .text-box > span {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 12px;
    background: #1890FF;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
  }

  .bottom-box {
    background: #FFFFFF;
  }
  .bottom-box > ul {
    list-style: none;
    display: flex;
    align-items: center;
    height: 50px;
  }
  .bottom-box > ul > li {
    margin: 0 20px 0 6px;
    font-size: 16px;
    padding-bottom: 10px;
    cursor: pointer;
  }
  .bottom-box > ul .active-li {
    color: #007AFF;
    border-bottom: 2px solid #007AFF;
  }

  .slide-left-enter {
    opacity: 0;
		transform: translate3d(-20px,0,0)
	}
	
	.slide-left-enter-to {
		opacity:1;
		transform: translate3d(0,0,0)
	}
	
	.slide-left-leave-to{
		opacity:0;
		transform: translate3d(20px,0,0)
	}
	.slide-left-enter-active, .slide-left-leave-active {
		transition: all .3s linear
  }

  /* .first-box, .second-box, .thired-box {
    width: 100%;
    height: 500px;
    background: #FFFFFF;
  } */
  .first-box > div {
    margin: 0 5px 22px 5px;
    border: 1px solid #E9E9E9;
  }
  .first-box > div > font {
    display: block;
    width: calc(100% - 20px);
    height: 42px;
    line-height: 42px;
    text-align: left;
    padding-left: 20px;
    color: #000;
    font-size: 14px;
  }
  .first-box > div:nth-child(1) > font {
    background: #E6F7FF;
  }
  .first-box > div:nth-child(2) > font {
    background: #FFEDE0;
  }
  .first-box > div:nth-child(3) > font {
    background:#FFD2D2;
  }
  .first-box > div > div {
    width: 100%;
    /* min-height: 0px; */
  }
  .first-box .empty-text {
    text-align: center;
    line-height: 250px;
    font-size: 14px;
  }

  .second-box, .thired-box {
    margin: 0 5px 22px 5px;
  }
  /* /deep/.second-box .el-table .el-table__empty-block, /deep/.thired-box .el-table .el-table__empty-block {
    min-height: 250px;
  } */
  /deep/.second-box .el-table th, /deep/.thired-box .el-table th {
    background:#f4f4f5;
    color: #000;
  }
 
  .second-box font, .thired-box font {
    display: block;
    width: calc(100% - 20px);
    height: 42px;
    line-height: 42px;
    text-align: left;
    padding-left: 20px;
    color: #000;
    font-size: 14px;
    background: #D9E6FF;
  }
</style>