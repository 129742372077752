
<template>
  <div class="dzfw-box">
    <div class="content-box"> 
      <h1>数据申请</h1>
      <div class="form-box">
        <el-form ref="form" :model="form" label-position="top" :rules="rules">
          <el-form-item label="需求标题" prop="tittle">
            <el-input v-model="form.tittle"></el-input>
          </el-form-item>
          <el-form-item label="数据用途" prop="Data_usage">
            <el-input v-model="form.Data_usage"></el-input>
          </el-form-item>
          <el-form-item label="需求描述（字段+条件）" prop="demand">
            <el-input v-model="form.demand"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="格式" prop="format">
            <el-checkbox-group v-model="form.format">
              <el-checkbox label="excel" name="type"></el-checkbox>
              <el-checkbox label="csv" name="type"></el-checkbox>
              <el-checkbox label="json" name="type"></el-checkbox>
              <el-checkbox label="其它" name="type"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">提交</el-button>
            <el-button @click="resetForm('form')">重置</el-button>
          </el-form-item>
        </el-form>  
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        tittle: '',
        Data_usage: '',
        demand: '',
        name: '',
        phone: '',
        email: '',
        delivery: false,
        format: [],
       
      },
      rules: {
        tittle: [{ required: true, message: '请输入需求标题', trigger: 'blur' }],
        Data_usage: [{ required: true, message: '请输入数据用途', trigger: 'blur' }],
        demand: [{ required: true, message: '请输入需求描述', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        format: [{type: 'array', required: true, message: '请至少选择一个格式', trigger: 'change' }],
      }
    }
    
  },
  created () {
    this.axiosUser_Info()
  },
  methods: {
    // 请求用户注册的信息并返显
    axiosUser_Info () {

    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style scoped>
  .dzfw-box {
    background: #FAFDFF;
  }
  .content-box > h1 {
    color: #1890FF;
    font-size: 20px;
    padding: 20px 0;
    /* text-align: left; */
  }
  .content-box {
    width: 65%;
    margin: 0 auto;
  }
  .content-box > .form-box {
    background: #ffffff;
    padding: 20px 0;
  }
  /deep/.form-box > .el-form {
    width: 75%;
    margin: 0 auto;
  }
  
</style>