<template>
  <div class="home">
    <div class="banner">
      <div class="banner-content">
        <div>
          <h1>特色数据库</h1>
          <p>Your Illustrator Graphic Creation Assistant</p>
          <p>From Ant Design AFX Team</p>
        </div>
        <div>
          <span>了解详情</span>
          <span>继续逛逛</span>
        </div>
      </div>
    </div>
    <div class="nav-box">
      <el-row :gutter="24"  class="row-wrap">
        <el-col :xs="8" :sm="8" :md="6" :lg="4" :xl="4" v-for="(item, index) in navdata1" :key="item.label">
          <div class="nav-contentbox"  @click="gotonav(item.code)">
            <div class="nav-box-top">
            <p>{{item.label}}</p>
            <p>{{item.total}}</p>
            </div>
            <div class="nav-box-front">
              <div>
                <img :src="imgsrc[index]" alt="">
              </div>
              <p>{{item.label}}</p>
            </div>
          </div>
          
        </el-col>
      </el-row>
    </div>
    <div class="report-box">
      <h2>数据报告资源</h2>
      <el-row type="flex" justify="center">
        <el-col :xs="22" :sm="22" :md="22" :lg="18" :xl="16">
          <div class="report-itembox">
            <div :class="['item-box', reportactive == 1? 'active-item-box':'']" @mouseenter="reportitembox(1)">
              <h3>产业监测报告</h3>
              <p>通过数据分析产业所处发展阶段及其在国民经济中的地位，分析影响行业发展的各种因素以及判断对行业的影响力度</p>
              <!-- <p>通过数据分析产业所处发展阶段及其在<br/>国民经济中的地位，分析影响行业发展<br/>的各种因素以及判断<br/>对行业的影响力度</p> -->
              <p>微观数据</p>
              <p>中观产业聚集、发展能力</p>
              <p>宏观经济数据</p>
              <div class="button-box">
                <span>
                  了解详情
                </span>
              </div>
            </div>
            <div :class="['item-box', reportactive == 2? 'active-item-box':'']" @mouseenter="reportitembox(2)">
              <h3>产业监测报告</h3>
              <p>通过数据分析产业所处发展阶段及其在国民经济中的地位，分析影响行业发展的各种因素以及判断对行业的影响力度</p>
              <p>微观数据</p>
              <p>中观产业聚集、发展能力</p>
              <p>宏观经济数据</p>
              <div class="button-box">
                <span>
                  了解详情
                </span>
              </div>
            </div>
            <div :class="['item-box', reportactive == 3? 'active-item-box':'']" @mouseenter="reportitembox(3)">
              <h3>产业监测报告</h3>
              <p>通过数据分析产业所处发展阶段及其在国民经济中的地位，分析影响行业发展的各种因素以及判断对行业的影响力度</p>
              <p>微观数据</p>
              <p>中观产业聚集、发展能力</p>
              <p>宏观经济数据</p>
              <div class="button-box">
                <span>
                  了解详情
                </span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      
    </div>
    <!-- <div class="flex-box">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div> -->
  </div>
</template>

<script>

export default {
  data () {
    return {
      // 图片资源
      imgsrc: [
        require('../static/img/home-nav-1.png'),
        require('../static/img/home-nav-2.png'),
        require('../static/img/home-nav-3.png'),
        require('../static/img/home-nav-4.png'),
        require('../static/img/home-nav-5.png'),
        require('../static/img/home-nav-6.png'),
        require('../static/img/home-nav-7.png'),
        require('../static/img/home-nav-8.png'),
        require('../static/img/home-nav-9.png'),
        require('../static/img/home-nav-10.png'),
        require('../static/img/home-nav-11.png'),
        require('../static/img/home-nav-12.png'),
      ],

      // 目录假数据
      navdata1: [
        {
          code: "nav1",
          label: "工商信息数据库",
          total: 129,
          children: [
            {
              code: "nav11",
              label: "子级1",
            },
            {
              code: "nav12",
              label: "子级1",
            },
            {
              code: "nav13",
              label: "子级1",
            },
          ]
        },
        {
          code: "nav2",
          label: "投融资数据库",
          total: 129,
          children: [
            {
              code: "nav11",
              label: "子级1",
            },
            {
              code: "nav12",
              label: "子级1",
            },
            {
              code: "nav13",
              label: "子级1",
            },
          ]
        },
        {
          code: "nav3",
          label: "企业信用数据库",
          total: 129,
          children: [
            {
              code: "nav11",
              label: "子级1",
            },
            {
              code: "nav12",
              label: "子级1",
            },
            {
              code: "nav13",
              label: "子级1",
            },
          ]
        },
        {
          code: "nav4",
          label: "企业资质数据库",
          total: 129
        },
        {
          code: "nav5",
          label: "司法文书数据库",
          total: 129
        },
        {
          code: "nav6",
          label: "知识产权数据库",
          total: 129
        },
        {
          code: "nav7",
          label: "招投标数据库",
          total: 129
        },
        {
          code: "nav8",
          label: "土地信息数据库",
          total: 129
        },
        {
          code: "nav9",
          label: "招聘数据库",
          total: 129
        },
        {
          code: "nav10",
          label: "百强企业数据库",
          total: 129
        },
        {
          code: "nav11",
          label: "特色产业数据库",
          total: 129
        },
        {
          code: "nav12",
          label: "股权穿透数据库",
          total: 129
        }
      ],

     
      reportactive: 2,
    }
  },
  methods: {
    reportitembox (code) {
      this.reportactive = code
    },

    gotonav (code) {
      this.$router.push({path: "/data_directory", query: {id: code}})
    }

  }
  
}
</script>
<style scoped>
.banner {
  width: 100%;
  height: 495px;
  background: url('../static/img/home-banner.png') no-repeat 100%/100% 100%;
  position: relative;
}
.banner-content {
  /* width: 30%; */
  position: absolute;
  top: 35%;
  left: 16%;
  text-align: center;
}

.banner-content h1 {
  font-size: 36px;
  color: #000;
  font-weight: 400;
  letter-spacing: 5px;
  margin-bottom: 10px;
}
.banner-content p {
  font-size: 18px;
  color: #464646;
  font-weight: 400;
  line-height: 26px;
}
.banner-content > div:last-child {
  margin-top: 20%;
  display: flex;
  justify-content: space-evenly;
}

.banner-content span {
  font-size: 16px;
  font-weight: 400;
  color: #597EF7;
  padding: 6px 25px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #597EF7;
  cursor: pointer;
}
.banner-content span:hover {
  background: #597EF7;
  color: #FFF;
}

.nav-box {
  padding: 30px 0;
  min-height: 200px;
  background: white;
  width: 75%;
  margin: 0 auto;
}
.row-wrap {
  min-height: 100%;
}
.row-wrap > .el-col {
  margin-top: 30px;
  padding-bottom: 30px;
}

.nav-contentbox {
  position: relative;
  width: 100%;
  height: 166px;
  transition: all .5s linear;
  transform-style:preserve-3d;
  transform-origin: center center;
  cursor: pointer;
}

.nav-contentbox:hover {
  transform: rotateX(90deg);
  transition: all .3s linear;
}


.nav-contentbox > .nav-box-front {
  transform: translateZ(-83px);
}
.nav-contentbox > .nav-box-top {
  transform: translateY(-83px) rotateX(-90deg) ;
  background: #008CF7;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-contentbox > .nav-box-top > p {
  color: #FFF;
  line-height: 28px;
}
/* .row-wrap > .el-col:hover {
  transform-origin: 0 0;
  transform: rotateX(90deg)
} */

/* .row-wrap > .el-col > .nav-box-top {
  position: absolute;
  width: 150px;
  height: 114px;
  transform: rotateX(90deg) translate3d(0,30px,0);
  background: red;
} */
.nav-box-top, .nav-box-front {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.nav-box-front {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav-box > div p {
  font-size: 16px;
  color: rgba(66, 71, 83, 0.8);
}
.nav-box .nav-box-front > div {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 145, 255, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-box-front > div > img {
  display: block;
  width: 48px;
  height: 48px;
}
.nav-box-front > p {
  margin-top: 45px;
}




.report-box {
  width: 100%;
  background: #F4F5F7;
  padding: 76px 0;
  text-align: center;
}
.report-box h2 {
  width: 100%;
  font-size: 28px;
  font-weight: 400;
  color: #424753;
  margin-bottom: 60px;
  text-align: center
}
.report-box .report-itembox {
  width: 100%;
  min-height: 530px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-itembox > .item-box {
  width: 30%;
  background: #FFF;
  transition: all .3s linear;
  cursor: pointer;
}
.report-itembox > .item-box > h3 {
  background: #ECEFF3;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: #424753;
}
.report-itembox > .item-box > p {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  color: #424753;
  line-height: 32px;
  border-bottom: 1px solid #EDEDEF;
}
.report-itembox > .item-box > p:nth-child(2) {
  margin: 25px auto;
}
.report-itembox > .item-box > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  padding-bottom: 30px;
}
.report-itembox > .item-box > div > span {
  padding: 8px 45px;
  border-radius:  30px;
  border: 2px solid rgba(108, 89, 180, 0.75);
  color: rgba(108, 89, 180, 0.75);
}
 

.report-itembox .active-item-box {
  transform: scale3d(1.33, 1.33, 1.33);
}
.report-itembox .active-item-box > h3 {
  background: #008CF7;
  color: #FFF;
}
.report-itembox > .active-item-box > div > span {
  border: 0;
  background: #0091FF;
  color: #FFF;
}
</style>
