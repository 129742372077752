<template>
  <div id="app">
    <Header v-if="!$route.meta.hid_header"></Header>
    <router-view/>
    <Footer v-if="!$route.meta.hid_footer"></Footer>
  </div>
</template>
<script>
import Header from './components/header'
import Footer from './components/footer'
export default {
  components: {Header, Footer},
  data () {
    return {

    }
  }
}
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: MicrosoftYaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  min-width: 1160px;
  height: 100%;
  position: relative;
}


</style>
