import Vue from 'vue'
import VueRouter from 'vue-router'


// 登录页，注册页，忘记密码修改页
import Loginpage from '../views/Loginpage.vue'
// 首页
import Home from '../views/Home.vue'
// 数据目录
import Data_directory from '../views/Data_directory.vue'
// 数据目录下级页面
import Overview from '../views/Overview.vue'
// 服务统计
import Service from '../views/Service.vue'
// 定制合作
import Custom_made from '../views/Custom_made.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/data_directory',
    name: 'Data_directory',
    component: Data_directory
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
  },
  {
    path: '/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/custom_made',
    name: 'Custom_made',
    component: Custom_made
  },
  {
    path: '/loginpage',
    name: 'Loginpage',
    component: Loginpage,
    meta: {
      hid_header: true,
      hid_footer: true
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
