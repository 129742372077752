<template>
  <div class="sever-box">
    <div class="banxin">
      <div class="top-box">
        <div v-for="(item, index) in toplabelarr" :style="{background: item.bgcolor}">
          <i :class="['iconfont', item.icon]"></i>
          <div >
            <p>{{item.label}}</p>
            <p>{{item.code}}</p>
          </div>
        </div>
      </div>
      <p>服务统计</p>
      <!-- 地图 -->
      <div class="map-box">
        <div id="map-box"></div>
        <div class="legend-box">
          <label :class="activespan_classname == 'fw'? 'active_span':''" @click="change_span('fw')">
            <span>地区访问量</span>
            <span v-show="activespan_classname == 'fw'"></span>
          </label>
          <label :class="activespan_classname == 'xz'? 'active_span':''" @click="change_span('xz')">
            <span>地区下载量</span>
            <span v-show="activespan_classname == 'xz'"></span>
          </label>
        </div>
      </div>
      
      <!-- 表格 -->
      <div class="table-box">
        <p>数据下载与访问监控</p>
        <el-table
          :data="tableData"
          :span-method="arraySpanMethod"
          style="width: 100%">
          <el-table-column
            align="center"
            label="序号"
            type="index"
            width="80">
          </el-table-column>
          <el-table-column
            label="项目"
            prop="xiangmu"
            align="center"
            width="150">
          </el-table-column>
          <el-table-column
            align="center"
            label="名称"
            prop="name">
          </el-table-column>
          <el-table-column label="配送信息" align="center">
            <el-table-column
              prop="zhanbi"
              align="center"
              label="占比"
              width="250">
              <template slot-scope="scope">
                <div :style="{width: scope.row.zhanbi, height: '38px', background: '#5B8FF9'}"></div>
              </template>
            </el-table-column>
            <el-table-column
              prop="total"
              align="center"
              label="数量"
              >
            </el-table-column>
            <el-table-column
              prop="paiming"
              align="center"
              label="排名"
              >
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
    
  </div>
</template>
<script>
import { EleResize } from '../utils/esresize.js'
import {mapjson} from '../axios/request.js'
import {sortarr} from '../utils/index'
export default {
  data () {
    return {
      toplabelarr: [
        {
          label: "数据包数",
          icon: "icon-dashujukeshihuaico-",
          bgcolor: "#389BF0",
          iconcolor: "",
          code: 100
        },
        {
          label: "数据条数", 
          icon: "icon-iconfontpaixingbang",
          bgcolor: "#F88706",
          iconcolor: 150,
          code: 30
        },
        {
          label: "浏览量", 
          icon: "icon-zoushitushixin",
          bgcolor: "#9239B7",
          iconcolor: 30,
          code: 10
        },
        {
          label: "下载量",
          icon: "icon-download",
          bgcolor: "#23BA43",
          iconcolor: 70,
          code: 180
        }
      ],
      dataobj: {
        obj1: {
          total1: 100,
          total2: 30,
          total3: 70,
          total4: 80,
        },
        obj2: {
          fw: [
            {
              name: "北京",
              value: 50
            },
            {
              name: "山西",
              value: 10
            }
          ],
          xz: [
            {
              name: "新疆",
              value: 10
            },
            {
              name: "河南",
              value: 30
            }
          ]
        },
        obj3: {
          fw: [
            {
              name: '投融资数据库',
              total: 520,
            },
            {
              name: '企业信用数据库',
              total: 240,
            },
            {
              name: '企业资质数据库',
              total: 639,
            },
            {
              name: '司法文书数据库',
              total: 321,
            },
            {
              name: '知识产权数据库',
              total: 157,
            }
          ],
          xz: [
            {
              name: '投融资数据库',
              total: 520,
            },
            {
              name: '企业信用数据库',
              total: 240,
            },
            {
              name: '企业资质数据库',
              total: 639,
            },
            {
              name: '司法文书数据库',
              total: 321,
            },
            {
              name: '知识产权数据库',
              total: 157,
            }
          ],
        }
      },
      tableData :[],
      activespan_classname: 'fw'
    }
  },
  created () {
    // 请求数据
    this.axiosdata()
  },
  methods: {
    axiosdata () {
      
      // 根据数据渲染地图
      this.maprender(this.dataobj.obj2, this.activespan_classname)
      // 处理表格
      this.tabledata(this.dataobj.obj3)
    },

    // 地图渲染
    maprender (obj, code) {
        // 排序获取到最大值，最小值

        let Jsonsortarr = sortarr(obj[code])

        this.$nextTick(() => {
          let myChart = this.$echarts.init(document.getElementById("map-box"))
          let resizeDiv = document.getElementById("map-box")
          let option = {
            color:['#1890FF','#1890FF'],
            visualMap: {
              splitNumber: 4,
              type: "piecewise",
              showLabel: true,
              min: 0,
              max: Jsonsortarr[0].value,
              itemWidth: 30,
              itemHeight: 15,
              inRange: {
                color: ["#4AC6F9", "#3E30FF"],
              },
              textStyle: {
                color: "#000",
                fontSize: 12
              },
              bottom: "20%",
              left: "10",
            },
            series: [
              {
                type: "map",
                map: "100000",
                // geoIndex: 0,
                showLegendSymbol: false,
                zoom: 0.9,
                rome: false,
                label: {
                  show: true,
                },
                data: obj[code],
                emphasis: {
                  itemStyle: {
                    areaColor: '#F98800'
                  }
                },
              }
            ],
          };
          
          mapjson('100000').then((data) => {
            this.$echarts.registerMap('100000', data.data)
            myChart.setOption(option);
            let listener = function () {
              myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
          })
        })
        
        
    },
    tabledata (obj) {
      let arr1 = sortarr(obj['fw'], 'total')
      let arr2 = sortarr(obj['xz'], 'total')
      
      console.log()

      let arr = arr1.concat(arr2)
      
      arr.forEach((item, index) => {
        if (index <= 4) {
          item.xiangmu = "数据访问量"
          item.zhanbi = item.total/arr1[0].total * 100 + '%'
          item.paiming = index + 1
        } else {
          item.xiangmu = "数据下载量"
          item.zhanbi = item.total/arr2[0].total * 100 + '%'
          item.paiming = index + 1 - 5
        }
      })
      this.tableData = arr
    },
    // 合并单元格
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {

      if (rowIndex === 0 && columnIndex === 1 || rowIndex === 5 && columnIndex === 1) {
        return [5, 1];
      } 
      else if ((rowIndex > 0 && rowIndex <= 4 && columnIndex === 1)  || (rowIndex > 5 && rowIndex <= 10 && columnIndex === 1)) {
        return [0, 0]
      }
    },
    // 手动切换legend
    change_span (code) {

      this.activespan_classname = code
      this.maprender(this.dataobj.obj2, this.activespan_classname)
    } 
  }
}
</script>
<style scoped>
  .sever-box {
    width: 100%;
    background: #F7FBFF;
    padding-bottom: 20px;
  }
  .banxin {
    width: 65%;
    margin: 0 auto;
    padding-top: 40px;
  }
  .top-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 112px;
  }
  .top-box > div {
    width: calc(20% - 40px);
    height: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    color: #F7FBFF;
    border-radius: 5px;
    padding-left: 40px;
  }
  .top-box > div > div {
    margin-left: 30px;
  }
  .top-box > div > div > p:nth-child(1){
    font-size: 16px;
    text-align: left;
    margin-left: 2px;
    /* margin-bottom: 15px; */
  }
  .top-box > div > div > p:nth-child(2){
    font-size: 36px;
    text-align: left;
    /* font-weight: bold; */
  }
  .top-box > div i {
    font-size: 40px;
  }

  .banxin > p {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bolder;
    text-align: left;
  }
  .banxin > .map-box {
    width: 100%;
    height: 528px;
    background: #FFFFFF;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.09);
    position: relative;
  }
  .banxin > .map-box > #map-box {
    width: 100%;
    height: 100%;
  }
  .banxin > .map-box > .legend-box {
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 100;
    display: flex;
  }
  .banxin > .map-box > .legend-box > label {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    cursor: pointer;
  }
  .banxin > .map-box > .legend-box > .active_span {
    color: #0091FF;
    font-size: 16px;
  }
  .banxin > .map-box > .legend-box > .active_span > span:first-child {
    margin-bottom: 5px;
  }
  .banxin > .map-box > .legend-box > .active_span > span:last-child {
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background:  #0091FF;
  }

  .table-box {
    width: calc(100% - 20px);
    padding: 0 10px;
    background: #FFFFFF;
    margin-top: 20px;
    padding-bottom: 40px;
  }
  .table-box > p {
    height: 64px;
    font-size: 16px;
    line-height: 64px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  /deep/.table-box > .el-table {
    margin-top: 20px;
    min-height: 300px;
  }
  /deep/.table-box > .el-table th {
    background:#f4f4f5;
    color: #000;
    font-size: 14px;
  }
  /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
    background: transparent;
  }
</style>