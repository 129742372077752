import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // 初始值
  state: {
    username: ""
  },
  // 写入 修改state值
  mutations: {
    // 用户名
    setusername (state, username) {
      state.username = username           // 修改sate值
      window.sessionStorage.setItem('username', JSON.stringify(username))   // 同步存储到window.sessionStorage中
    }
  },
  // 获取值
  //判断是否有值切不等于默认值,如果不等于重新赋值，返回给state的token
  getters: {
    //获取用户名
    getusername (state) {
      if (state.username !== '') {
        state.username = JSON.parse(window.sessionStorage.getItem('username'))
      }
      return state.username
    }
  
  },
  actions: {
  },
  modules: {
  }
})
