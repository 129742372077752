<template>
  <div class="header">
    <div class="logo-box"></div>
    <div class="menu-box">
      <span 
        :class="item.name == $route.name || (item.name == 'Data_directory' && $route.name == 'Overview')? 'active-span':''"
        @click="goto(item.name)"
        v-for="item in routedata" 
        :key="item.name" 
      >
        {{item.label}}
      </span>
    </div>
    <div class="toolp-box">
      <div>
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="dropdown-menu-box">
            <el-avatar size="medium" icon="el-icon-user-solid"></el-avatar>
            <span>{{$store.state.username == ''? '账号未登录':$store.state.username}}</span>
          </div>
          
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="$store.state.username!=''" icon="iconfont icon-dengchu" command="a">退出</el-dropdown-item>
            <el-dropdown-item v-else icon="iconfont icon-denglu" command="b">登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        
      </div>
    </div>
  </div>
</template>
<script>
import {logout} from '../axios/request.js'
export default {
  data () {
    return {
      routedata: [
        {
          name: "Home",
          label: "首页"
        },
        {
          name: "Data_directory",
          label: "数据目录"
        },
        {
          name: "Service",
          label: "服务统计"
        },
        {
          name: "Custom_made",
          label: "定制合作"
        }
      ],
      // 用户名
      // username: "账号未登录"
    }
  },
  methods: {
    handleCommand(command) {
      if (command == 'a') {
        this.logoutapi()
      } else if (command == 'b') {
        this.goto('Loginpage')
      }
    },
    goto (name) {
      this.$router.push({name: name})
    },
    logoutapi () {
      logout().then(res => {
        if (res.data.code == 0) {
          this.$message({
            message: '账号已退出',
            type: 'success'
          });
          this.$store.commit('setusername', '')
        } else {
          this.$message({
            message: '账号退出失败！',
            type: 'error'
          });
        }
      }).catch(err => {
        this.$message({
          message: 'err',
          type: 'error'
        });
      })
    }
  }
}
</script>
<style scoped>
  .header {
    height: 48px;
    width: 100%;
    background: #001529;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header > div {
    height: 100%;
  }
  .logo-box {
    width: 200px;
  }
  .menu-box {
    flex: 1;
    font-size: 18px;
    color: #ffffff;
    font-weight: 400;
    display: flex;
    justify-content: center;
  }
  
  .menu-box > span {
    margin: 0 40px;
    height: calc(100% - 4px);
    padding: 0 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .active-span {
    border-bottom: 2px solid #ffffff;
  }

  .toolp-box {
    min-width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
 
  .dropdown-menu-box {
    display: flex;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
  }
  .dropdown-menu-box > span {
    margin-left: 10px;
  }
</style>