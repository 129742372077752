<template>
  <div class="data-box">
    <el-row justify="center" type="flex" class="input-box">
      <el-col :xs="10" :sm="10" :md="10" :lg="10" :xl="10" >
        <el-input v-model="input" placeholder="请输入" clearable>
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </el-col>
    </el-row>

    <!-- 搜索历史 -->
    <el-row justify="center" type="flex" class="inputhistory-box">
      <el-col :xs="16" :sm="16" :md="16" :lg="10" :xl="10">
        <span v-for="item in inputhistory">{{item}}</span>
      </el-col>
    </el-row>
    
    <!-- 数据列表 -->
    <div class="content-box">
      <el-row type="flex" justify="center" class="content-box-border">
        <div>
          <div class="left-menubox">
            <el-menu
              :unique-opened="true"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose">
              <el-submenu v-for="(item, index) in menudata" v-if="item.children" :key="item.code" :index="item.code">
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span>{{item.label}}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-for="(items, indexs) in item.children" :index="index+''+indexs" :key="items.code">{{items.label}}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item v-else :index="item.code">
                <i class="el-icon-menu"></i>
                <span slot="title">{{item.label}}</span>
              </el-menu-item>
            </el-menu>
            <el-menu
              :unique-opened="true"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose">
              <el-submenu v-for="(item, index) in Resourcesmenu" v-if="item.children" :key="item.code" :index="item.code">
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span>{{item.label}}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-for="(items, indexs) in item.children" :index="index+''+indexs" :key="items.code">{{items.label}}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-menu-item v-else :index="item.code">
                <i class="el-icon-menu"></i>
                <span slot="title">{{item.label}}</span>
              </el-menu-item>
              
            </el-menu>
            <el-menu
              :unique-opened="true"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose">

              <el-submenu  v-for="(item, index) in starmenu" v-if="item.children" :key="item.code" :index="item.code">
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span>{{item.label}}</span>
                </template>

                <el-menu-item-group>
                  <el-menu-item v-for="(items, indexs) in item.children" :index="items.code" :key="items.code">
                    <i v-for="indexss in 5" class="el-icon-star-on" :style="{color: indexs+indexss<=5? 'rgb(247, 186, 42)':'#909399'}"></i>
                    <span>{{"C"+indexs+1}}</span>
                  </el-menu-item>
                </el-menu-item-group>

              </el-submenu>

              <el-menu-item v-else :index="item.code">
                <i class="el-icon-menu"></i>
                <span slot="title">{{item.label}}</span>
              </el-menu-item>
              
            </el-menu>
          </div>
        </div>
        
    
        <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="14">
          <div class="tittle-box"> <span>数据列表</span> </div>
          <div class="content-data-box">
            <el-row class="data-box-row" :gutter="20">
              <el-col :span="8" v-for="(item, index) in datalist" :key="'A'+index" class="data-box-item-8">
                <div class="data-box-item" @click="goto('/overview', item)">
                  <div class="img-fufei-box">
                    <el-image
                      style="width: 100%;"
                      :src="item.imgsrc"
                      fit="cover">
                    </el-image>
                    <div class="fufei-box" :style="{background: item.entid == '11'? '#FF6600':'#CC3333'}">
                      <span>{{item.entid == '11'? '免费':'付费'}}</span>
                    </div>
                  </div>
                  
                  <!-- <img :src="item.imgsrc" alt=""> -->
                  <div class="data-box-item2">
                    <p>{{item.tittle}}</p>
                    <p>{{item.text}}</p>
                  </div>
                  <div class="data-box-item3">
                    <span>{{item.time}}</span>
                    <span>{{item.tag1}}</span>
                    <span>{{item.tag2}}</span>
                  </div>
                  
                  
                </div>
              </el-col>
            </el-row>
            <span @click="searchaxios('add')" class="adddata">加载更多</span>
          </div>
        </el-col>
      </el-row>
     
    </div>
    


   
  </div>
</template>
<script>
export default {
  data () {
    return {
        
        // 资源类型菜单
        // 数据评分菜单
      // 主题菜单
      menudata: [
        {
          label: "主题",
          code: "A",
          iconclss: "",
          children: [
            {
              label: "主题1",
              code: "A1",
              iconclss: "",
            },
            {
              label: "主题2",
              code: "A2",
              iconclss: "",
            }
          ]
        }
      ],

      Resourcesmenu: [
         {
          label: "资源类型",
          code: "B",
          iconclss: "",
          children: [
            {
              label: "数据资源A",
              code: "B1"
            },
            {
              label: "数据资源B",
              code: "B2"
            }
          ]
        },
      ],
      starmenu: [
        {
          label: "数据评分",
          code: "C",
          iconclss: "",
          children: [
            {
              label: "1-2.9",
              code: "C5",
              value: 5
            },
            {
              label: "3-5",
              code: "C4",
              value: 4
            },
            {
              label: "1-2.9",
              code: "C3",
              value: 3
            },
            {
              label: "3-5",
              code: "C2",
              value: 2
            },
            {
              label: "1-2.9",
              code: "C1",
              value: 1
            },
            {
              label: "1-2.9",
              code: "C0",
              value: 0
            }
          ]
        }
      ],

      // menulist: [],
      input:"",
      inputhistory: [],

      datalist: [
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "11",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "21",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "11",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "21",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "11",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "21",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "11",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "21",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },
        {
          imgsrc: require('../static/img/datalist1.png'),
          entid: "11",
          tittle: "生命在于折腾，不在折腾中崩溃",
          text: "产品创造于工厂，而品牌创造于心灵产品创造于工厂，而品牌创造于心产品创造于工",
          time: "2020.10.23",
          tag1: "主页标签占位",
          tag2: "内容标签占位"
        },

      ],
      navcode: "",

      pageSize: 9,
      pageIndex: 0
    }
  },
  created () {
    var canvas = document.getElementsByClassName('canvas');
    if (canvas.getContext){
      var ctx = canvas.getContext('2d');
        ctx.beginPath();
        ctx.moveTo(75,50);
        ctx.lineTo(100,75);
        ctx.lineTo(100,25);
        ctx.fill();
    }
    this.navcode = this.$route.query.id
    
    

  },
  methods: {
    // 跳转路由
    goto (path, data) {
      window.sessionStorage.setItem('itemdata', JSON.stringify(data))
      this.$router.push({path: path})
    },
    search () {
      if (this.input) {
        let indexcode = this.inputhistory.indexOf(this.input)
        if (indexcode != -1) {
          this.inputhistory.splice(indexcode, 1)
          this.inputhistory.unshift(this.input)
        } else {
          if (this.inputhistory.length < 3) {
            this.inputhistory.unshift(this.input)
          } else {
            this.inputhistory.pop()
            this.inputhistory.unshift(this.input)
          }
        }
        this.searchaxios()
      }
    },
    // 查询接口
    searchaxios (code) {
      if (code == 'add') {
        this.pageIndex++
      }
    },
    // 目录请求
    menuaxios () {
      // 判断 路由query参数 
      // 有参数
      if (this.navcode) {

      } 
      // 无参数
      else {

      }
      // 调接口
      // 接口成功 
        
        
        // 请求页面内容
    },
    // 点击目录请求数据
    dataaxios () {

    },

    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },


  }
}
</script>
<style scoped>
  .data-box {
    width: 100%;
    background: #F7FBFF;
  }
  .input-box {
    padding: 20px 0 10px 0;
  }
  .inputhistory-box {
    height: 30px;
  }
  .inputhistory-box > .el-col {
    display: flex;
    justify-content: flex-start;
  }
  .inputhistory-box span {
    color: #949699;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
  }
  .content-box {
    width: 100%;
    padding-bottom: 30px;
    
  }
  .content-data-box {
    position: relative;
    width: 100%;
    padding-bottom: 30px;
    
  }
  .content-box > .content-box-border > .el-col {
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  }
  .content-box .el-col:nth-child(2) > div {
    background: #FFF;
  }
  .content-box .tittle-box {
    height: 47px;
    font-size: 24px;
    color: #000;
    line-height: 47px;
    text-align: left;
  }
  .content-box .el-col:nth-child(2) div:first-child > span {
    margin-left: 3px;
    padding-left: 5px;
    border-left: 2px solid #0091FF;
  }
  .data-box-item {
    margin-bottom: 20px;
    border-radius: 2px;
    overflow: hidden;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  .data-box-item img {
    display: block;
    width: 100%;
    height: 208px;
  }
  .data-box-row {
    margin-left: 0px !important; 
    margin-right: 0px !important;
  }
  .data-box-item2 {
    text-align: left;
    width: 90%;
    margin: 0 auto;
  }
   .data-box-item3 {
     width: 100%;
     text-align: left;
   }
  .data-box-item2 > p:nth-child(1) {
    font-size: 14px;
    color: #000000;
    font-weight: 700;
    line-height: 32px;
    overflow: hidden;
    text-overflow:ellipsis; 
    white-space:nowrap;
    letter-spacing: 0.3em;
  }
  .data-box-item2 > p:nth-child(2) {
    font-size: 14px;
    height: 45px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 24px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 15px;
  }
  .data-box-item3 {
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 14px;
    line-height: 32px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .data-box-item3 > span {
    flex: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .data-box-item3 > span:nth-child(2) {
    border-left: 1px dashed rgba(0, 0, 0, 0.1);
    border-right: 1px dashed rgba(0, 0, 0, 0.1);
  }
  .img-fufei-box {
    position: relative;
  }
  .fufei-box {
    position: absolute;
    right: 0;
    top: 0;

    /* width: 0px;
    height: 0px;
    border-top: 20vw;
    border-bottom: 20vw;
    border-left: 30vw;
    border-right: 30vw;
    border-color: transparent red transparent transparent; */
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    -moz-transform-origin: 100% 100%;
    -webkit-transform-origin:100% 100%;
    -o-transform-origin:100% 100%;
    transform:  rotate(45deg) translateY(-45%);
  }
  .fufei-box > span {
    position: absolute;
    bottom: 5%;
    left: 55%;
    font-size: 14px;
    color: #FFF;
    text-align: center;
  }


  
  .adddata {
    width: 10%;
    margin: 0 auto;
    padding: 7px 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
  }

  .left-menubox {
    width: 200px;
    min-height: 400px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    margin-top: 47px;
    background: #FFF;
  }

  /deep/.el-menu-vertical-demo {
    width: 100%;
    min-height: 56px;
    text-align: left;
    border-right: 0;
  }
  
  /deep/.el-submenu {
    margin-top: 20px;
    background: #389bf0;
  }
  /deep/ .el-submenu__title,  /deep/ .el-submenu__title > i{
    color: #FFF;
  }
  /deep/ .el-rate {
    height: 50px;
    line-height: 3;
    background: transparent;
  }
  /deep/.el-submenu__title:focus, /deep/.el-submenu__title:hover {
    background: transparent !important;
  }
  /deep/.el-menu-item-group__title {
    padding: 0;
  }
  /deep/ .el-icon-star-on {
    width: auto !important;
    margin-right: 2px !important;
  }
  
 
</style>